import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Projects from "../components/projects"

const ProjectsPage = ({ data }) => (
  <Layout>
    <SEO title="Projects" />
    <Projects entries={data.allContentfulProject.nodes} />
    <Footer contacts={data.allContentfulContact.edges} />
  </Layout>
)

export default ProjectsPage

export const pageQuery = graphql`
  query Projects {
    allContentfulProject {
      nodes {
        coverImage {
          file {
            url
          }
        }
        showTitle
        slug
        text {
          text
        }
        title
      }
    }
    allContentfulContact {
      edges {
        node {
          id
          title
          contact {
            contact
          }
        }
      }
    }
  }
`
