import React from "react"
import { Link } from "gatsby"

const Project = ({ title, slug }) => {
  return (
    <li>
      <h2>
        <Link to={`/projects/${slug}`}>{title}</Link>
      </h2>
    </li>
  )
}

const Projects = ({ entries }) => (
  <section id="projects">
    <h1>Projects</h1>
    <ul className="projects__list">
      {entries.map(entry => (
        <Project {...entry} />
      ))}
    </ul>
  </section>
)

export default Projects
