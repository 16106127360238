import React from "react"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const Contact = ({ title, contact }) => (
  <li>
    <h4>{title}</h4>
    {contact && (
      <div
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(JSON.parse(contact.contact)).replace(
            /\n/g,
            "<br />"
          ),
        }}
      />
    )}
  </li>
)

const Footer = ({ contacts }) => (
  <footer>
    <div id="social-media">
      <h2>Follow</h2>
      <div>
        <a
          href="https://www.instagram.com/iskandar.widjaja/"
          className="instagram"
        >
          Follow on Instagram
        </a>
        <a
          href="https://open.spotify.com/artist/1UYYiIEPhxozP9CtgXHhYD"
          className="spotify"
        >
          Follow on Spotify
        </a>
        <a
          href="https://www.facebook.com/Iskandar-Widjaja-233075790071006/"
          className="facebook"
        >
          Follow on Facebook
        </a>
      </div>
    </div>
    <div id="contact">
      <h2>Contact</h2>
      <ol>
        {contacts.map(contact => (
          <Contact key={`contact-${contact.node.id}`} {...contact.node} />
        ))}
      </ol>
    </div>
    <div id="credits">
      <p className="design">
        Design and development by
        <a
          className="hagenburger"
          href="https://www.hagenburger.net"
          title="design, development, sass, compass"
        >
          Nico Hagenburger/Hagenburger GmbH, Berlin
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
